<template>
  <b-card
    title="Title"
    header-tag="header"
    footer-tag="footer"
  >
    <template #header>
      <h6 class="mb-0">
        Header Slot
      </h6>
    </template>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="First Name"
            label-for="mc-first-name"
          >
            <b-form-input
              id="mc-first-name"
              placeholder="First Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Last Name"
            label-for="mc-last-name"
          >
            <b-form-input
              id="mc-last-name"
              placeholder="Last Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="City"
            label-for="mc-city"
          >
            <b-form-input
              id="mc-city"
              placeholder="City"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Country"
            label-for="mc-country"
          >
            <b-form-input
              id="mc-country"
              placeholder="Country"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Company"
            label-for="mc-company"
          >
            <div class="form-label-group">
              <b-form-input
                id="mc-company"
                placeholder="Company"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Email"
          >
            <div class="form-label-group">
              <b-form-input
                id="mc-email"
                type="email"
                placeholder="Email"
              />
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              id="checkbox-10"
              name="checkbox-10"
              value="Remember_me"
            >
              Remember me
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            size="sm"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            size="sm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'NIP',
          field: 'nip',
        },
        {
          label: 'Nama Lengkap',
          field: 'name',
        },
        {
          label: 'L/P',
          field: 'gender',
        },
        {
          label: 'Aktif',
          field: 'is_active',
        },
        {
          label: 'Jabatan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Masa Kerja',
          field: 'working_period',
        },
        {
          label: 'Usia',
          field: 'age',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAllEmployee()
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Parent.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    toRouteEditParent(val) {
      this.$router.push({ name: 'data-orangtua-edit', params: { id: val } })
    },
    toRouteDetailParent(val) {
      this.$router.push({ name: 'data-orangtua-detail', params: { id: val } })
    },
    async getAllEmployee() {
      try {
        const response = await this.$http.get('/employees')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Parent Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/studentfamilies/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Parent Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Parent Not Success')
        console.log(err)
      }
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
